import React from 'react';
import axios from 'axios';
import eventApi from '../../API/eventApi'
import api from '../../API/api'
import APIServices from '../../API/apiservices'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { images } from '../../Constants/images';
import Validator from '../../Constants/validator';
import _ from 'lodash';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap-modal';
import './login.scss';
import cookieStorage from '../../Constants/cookie-storage';
import config from '../../Constants/config';
import Constants from '../../Constants/validator';
import {Redirect} from 'react-router-dom';
import anxilla_logo from './anxilla_logo.png'

const apiServices = new APIServices();

let error = Constants.getParameterByName('error', window.location.href)
error = error ? error : ''

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:"",
      loggedIn: false,
      loginFailMsg: ""
    }
    // this.textInput = React.createRef();
  }

  componentDidMount() {
    cookieStorage.deleteCookie();
    localStorage.clear();
    // setTimeout(() => {
    //   this.textInput.current.focus();
    // }, 800);
    // this.registerloginButtonClick();
  }

  // registerloginButtonClick = () => {
  //   document.addEventListener("keydown", (event) => {
  //     if (event.keyCode === 13) {
  //       this.login();
  //     }
  //   });
  // }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // this._validate(e);
  };

  _validate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let msg = ``;

    if (name === 'email') {
      let isValid = Validator.validateEmail(value);
      msg = value === '' ? `Username should not be empty*` : (!isValid ? `Please enter valid username` : ``);
      this.setState({ emailMessage: msg });
    }
    else if (name === 'password') {
      // let isValid = Validator.validatePassword(value);
      msg = value === '' ? `Password should not be empty*` : (value.length < 7 ? `Please enter valid password` : ``);
      this.setState({ passwordMessage: msg });
    }
    setTimeout(() => {
      this.formValid();
    }, 600);
  }

  formValid = () => {
    const { emailMessage, passwordMessage, email, password } = this.state;
    const errorCheck = _.isEmpty(emailMessage && passwordMessage)
    const emptyDataCheck = !_.isEmpty(email && password)
    if (errorCheck && emptyDataCheck) {
      this.setState({ disable: false })
    } else {
      this.setState({ disable: true })
    }
  }

  resetState = () => {
    this.setState({
      email: '',
      password: '',
      emailMessage: '',
      passwordMessage: '',
      disable: true,
      loading: false
    })
  }

  login = () => {
    const { email, password} = this.state;
    let errorMsg = "";
    if(email === ""){
      errorMsg = "Please enter your email-id";
    }else if(password === ""){
      errorMsg = "Please enter your password"
    }else{
      if(email === 'indigo@anxilla.com' && password === '1ND!g0Anx1II@'){
        this.setState({
          loggedIn: true
        })
        window.location.href = `/proceed?whereto=posDashboard&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbklEIjoiSTBCdFRjM3ZBR0FjZFlRWFpKRFZoQ25nQ2ZGc2NUa0ZHbGp6UGdJNXc3MVRwY1RNRkRMbXotNTM1WVlFMjIiLCJlbWFpbCI6ImluZGlnb0BhbnhpbGxhLmNvbSIsInVzZXJJRCI6IlYxMDI2NDUiLCJhZ2VudGlkIjoiZTQ5Zjc5NWYiLCJpZCI6MSwiZXhwIjoxNjc4NjY1NTQwfQ.4-7FR7qVO_JSJqRz9zNbJaDjNgn-iMf0XvG9JsyUgN4`
      }else{
        errorMsg = "The email-id or password that you've entered is incorrect"
      }
    }

    if(errorMsg!=''){
      this.setState({
        loginFailMsg: errorMsg
      })
    }
    
  }

  closeModal() {
    this.setState({ displayModal: false })
  }
  // const classes = useStyles();

  render() {
    const { loginFailMsg } = this.state;
    if(this.state.loggedIn){
      console.log(this.state);
      return <Redirect to = "/posDashboard" />
    }
    return (
      <Container className={`login`} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`paper`}>
          <div className={`top-login`}>
            <Avatar className={`avatar`}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
          </Typography>
          </div>
          <div className="logo-img">
            <img alt="logo" src={anxilla_logo} />
          </div>
          <div className="login-container">
            <div className="input-container">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                id="email"
                onChange = {(e) => this.handleChange(e)}
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter Your Password"
                onChange = {(e) => this.handleChange(e)}
              />
            </div>
            <p className="text-center">{ loginFailMsg }</p>
          </div>
          <Button
            // disabled={true}
            type={`submit`}
            fullWidth
            variant="contained"
            color="primary"
            className={`submit`}
            onClick={() => this.login()}
          >
            {this.state.loading ? <img src={images.ellipsis_loader} alt='' /> : `Click here to Login`}
          </Button>
          <p>{`${decodeURIComponent(error)}`}</p>
        </div>
      </Container>
    );
  }
}

export default Login;