import React, { Component } from 'react';
import APIServices from '../../API/apiservices';
import eventApi from '../../API/eventApi';
import ChartModelDetails from '../../Component/chartModel'
import DatatableModelDetails from '../../Component/dataTableModel'
import DataTableComponent from '../../Component/DataTableComponent'
import DownloadCSV from '../../Component/DownloadCSV';
import Loader from '../../Component/Loader';
import TotalRow from '../../Component/TotalRow';
import color from '../../Constants/color'
import $ from 'jquery';
import '../../App.scss';
import './routePerfCons.scss';
import TopMenuBar from '../../Component/TopMenuBar';
import RouteCustomHeaderGroup from './RouteCustomHeaderGroup';
import cookieStorage from '../../Constants/cookie-storage';
import String from '../../Constants/validator';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import BrowserToProps from 'react-browser-to-props';
import URLSearchParams from '../../Constants/validator';
import Modal from 'react-bootstrap-modal';


const apiServices = new APIServices();

const ancillary = URLSearchParams.getParameterByName('ancillaryRoute', window.location.href)
const route = URLSearchParams.getParameterByName('route', window.location.href)


const currentYear = new Date().getFullYear()
let monthData = [];

let bcData = [];
let baseAccess = '';

class RoutePerfCons extends Component {
  constructor(props) {
    super(props);
    this.userDetails = JSON.parse(cookieStorage.getCookie('userDetails'))
    this.selectedRegion = null;
    this.selectedCountry = null;
    this.selectedRoute = null;
    this.state = {
      showLastYearRows: false,
      routeMonthRowClassRule: {
        'highlight-row': 'data.highlightMe',
      },
      routeMonthDetails: [],
      routeMonthColumns: [],
      monthTotalData: [],
      drillDownTotalData: [],
      drillDownData: [],
      drillDownColumn: [],
      segmentData: [],
      segmentColumn: [],
      odData: [],
      odColumn: [],
      modelRegionDatas: [],
      modelregioncolumn: [],
      modalData: [],
      modalCompartmentColumn: [],
      modalCompartmentData: [],
      tableDatas: true,
      gettingMonth: null,
      gettingYear: null,
      monthTableTitle: '',
      tableTitle: '',
      tabLevel: '',
      cabinOption: [],
      getCabinValue: [],
      cabinSelectedDropDown: [],
      cabinDisable: true,
      currency: 'bc',
      chartVisible: false,
      tableModalVisible: false,
      tabName: 'Region',
      regionId: '*',
      countryId: '*',
      routeId: '*',
      leg: '*',
      flight: '*',
      type: 'Null',
      baseAccess: '',
      routeGroup: '',
      accessLevelDisable: false,
      selectedData: 'Null',
      loading: false,
      loading2: false,
      loading3: false,
      firstLoadList: false,
      firstHome: true,
      posContributionModal: false
    }
    this.sendEvent("1", "User viewed Route Page", "/route", "Route Page");
  }

  sendEvent = (id, description, path, page) => {
    var eventData = {
      event_id: id,
      description: description,
      where_path: path,
      page_name: page
    }
    eventApi.sendEvent(eventData)
  }

  componentDidMount() {
    var self = this;

    // self.getFiltersValue();

    // apiServices.getClassNameDetails().then((result) => {
    //   if (result) {
    //     var classData = result[0].classDatas;
    //     self.setState({ cabinOption: classData, cabinDisable: false })
    //   }
    // });

    const month = apiServices.getRoutePerfConsMonthDummy();
    var totalData = month[0].totalData;
    var columnName = month[0].columnName;
    var rowData = month[0].rowData;

    self.setState({ routeMonthDetails: rowData, routeMonthColumns: columnName, monthTotalData: totalData })

    const drilldown = apiServices.getRoutePerfConsDrillDownDummy();
    var totalData = drilldown[0].totalData;
    var columnName = drilldown[0].columnName;
    var rowData = drilldown[0].rowData;

    self.setState({ drillDownData: rowData, drillDownColumn: columnName, drillDownTotalData: totalData })

  }

  componentDidUpdate() {
    // window.onpopstate = e => {
    //   const obj = this.props.browserToProps.queryParams;
    //   let data = Object.values(obj);
    //   let title = Object.keys(obj);
    //   const lastIndex = title.length - 1
    //   if (data[0] !== 'undefined') {
    //     this.pushURLToBcData(obj, title, data, lastIndex)
    //     this.setState({ firstHome: true })
    //   } else {
    //     if (this.state.firstHome) {
    //       this.homeHandleClick();
    //     }
    //   }
    // }
  }

  // pushURLToBcData(obj, title, data, lastIndex) {
  //   const self = this;
  //   let group = []
  //   let region = []
  //   let country = []
  //   let city = []

  //   let routeGroup = obj['RouteGroup']
  //   this.setState({ routeGroup: routeGroup })
  //   window.localStorage.setItem('RouteGroupSelected', JSON.stringify(group.concat([routeGroup])))

  //   if (obj.hasOwnProperty('Region') && !bcData.some(function (o) { return o["title"] === "Region"; })) {
  //     let data = obj['Region']
  //     let bcContent = obj['Region'];
  //     let multiSelectLS;
  //     let regionId;

  //     if ((data).includes(',')) {
  //       data = `'${data.split(',').join("','")}'`;
  //     } else if (data.charAt(0) !== "'" && data.charAt(data.length - 1) !== "'") {
  //       data = `'${data}'`
  //     }

  //     if (bcContent.charAt(0) === "'" && bcContent.charAt(bcContent.length - 1) === "'") {
  //       regionId = bcContent.substring(1, bcContent.length - 1)
  //     } else if (bcContent.includes(',')) {
  //       multiSelectLS = bcContent.split(',');
  //       regionId = bcContent;
  //     } else {
  //       regionId = bcContent;
  //     }

  //     bcData.push({ "val": regionId, "title": 'Region' });
  //     self.setState({ regionId: data })
  //     let regionLS = bcContent.includes(',') ? multiSelectLS : region.concat([regionId])
  //     window.localStorage.setItem('RouteRegionSelected', JSON.stringify(regionLS))
  //   }
  //   if (obj.hasOwnProperty('Country') && !bcData.some(function (o) { return o["title"] === "Country"; })) {
  //     let data = obj['Country']
  //     let bcContent = obj['Country']
  //     let multiSelectLS;
  //     let countryId;

  //     if ((data).includes(',')) {
  //       data = `'${data.split(',').join("','")}'`;
  //     } else if (data.charAt(0) !== "'" && data.charAt(data.length - 1) !== "'") {
  //       data = `'${data}'`
  //     }
  //     if (bcContent.charAt(0) === "'" && bcContent.charAt(bcContent.length - 1) === "'") {
  //       countryId = bcContent.substring(1, bcContent.length - 1)
  //     } else if (bcContent.includes(',')) {
  //       multiSelectLS = bcContent.split(',');
  //       countryId = bcContent;
  //     } else {
  //       countryId = bcContent;
  //     }
  //     bcData.push({ "val": countryId, "title": 'Country' });
  //     self.setState({ countryId: data })
  //     let countryLS = bcContent.includes(',') ? multiSelectLS : country.concat([countryId])
  //     window.localStorage.setItem('RouteCountrySelected', JSON.stringify(countryLS))
  //     console.log('rahul Country', countryId, data)

  //   }
  //   if (obj.hasOwnProperty('Route') && !bcData.some(function (o) { return o["title"] === "Route"; })) {
  //     let data = obj['Route']
  //     let bcContent = obj['Route']
  //     let multiSelectLS;
  //     let routeId;

  //     if ((data).includes(',')) {
  //       data = `'${data.split(',').join("','")}'`;
  //     } else if (data.charAt(0) !== "'" && data.charAt(data.length - 1) !== "'") {
  //       data = `'${data}'`
  //     }
  //     if (bcContent.charAt(0) === "'" && bcContent.charAt(bcContent.length - 1) === "'") {
  //       routeId = bcContent.substring(1, bcContent.length - 1)
  //     } else if (bcContent.includes(',')) {
  //       multiSelectLS = bcContent.split(',');
  //       routeId = bcContent;
  //     } else {
  //       routeId = bcContent;
  //     }

  //     bcData.push({ "val": routeId, "title": 'Route' });
  //     self.setState({ routeId: data })
  //     let cityLS = bcContent.includes(',') ? multiSelectLS : city.concat([routeId])
  //     window.localStorage.setItem('RouteSelected', JSON.stringify(cityLS))
  //     console.log('rahul Route', routeId, data)

  //   }
  //   if (obj.hasOwnProperty('Leg') && !bcData.some(function (o) { return o["title"] === "Leg"; })) {

  //     bcData.push({ "val": obj['Leg'], "title": 'Leg' });
  //     console.log('rahul Leg', obj['Leg'])

  //     self.setState({ leg: `'${obj['Leg']}'` })
  //     window.localStorage.setItem('LegSelected', obj['Leg'])
  //   }
  //   if (obj.hasOwnProperty('Flight') && !bcData.some(function (o) { return o["title"] === "Flight"; })) {

  //     bcData.push({ "val": obj['Flight'], "title": 'Flight' });
  //     console.log('rahul Flight', obj['Flight'])

  //     self.setState({ flight: obj['Flight'] })
  //     window.localStorage.setItem('FlightSelected', obj['Flight'])
  //   }

  //   console.log('rahul bcData before', bcData, lastIndex)

  //   if (bcData.length > 0) {
  //     var removeArrayIndex = bcData.slice(0, lastIndex);
  //     bcData = removeArrayIndex;
  //   }
  //   console.log('rahul bcData after', bcData)


  //   this.browserBackButtonClick(data[lastIndex], title[lastIndex])
  // }

  // getFiltersValue = () => {
  //   bcData = []
  //   let routeGroup = window.localStorage.getItem('RouteGroupSelected')
  //   let RegionSelected = window.localStorage.getItem('RouteRegionSelected')
  //   let CountrySelected = window.localStorage.getItem('RouteCountrySelected')
  //   let RouteSelected = window.localStorage.getItem('RouteSelected')
  //   let rangeValue = JSON.parse(window.localStorage.getItem('rangeValue'))
  //   let getCabinValue = window.localStorage.getItem('CabinSelected')
  //   let LegSelected = window.localStorage.getItem('LegSelected')
  //   let FlightSelected = window.localStorage.getItem('FlightSelected')

  //   if (routeGroup === null || routeGroup === '' || routeGroup === 'Null') {
  //     if (Object.keys(this.userDetails.route_access).length > 0) {
  //       routeGroup = this.userDetails.route_access['selectedRouteGroup']
  //     } else {
  //       routeGroup = ['Network']
  //     }
  //   } else {
  //     routeGroup = JSON.parse(routeGroup)
  //   }

  //   let cabinSelectedDropDown = getCabinValue === null || getCabinValue === 'Null' ? [] : JSON.parse(getCabinValue);
  //   getCabinValue = cabinSelectedDropDown.length > 0 ? cabinSelectedDropDown : 'Null'

  //   this.setState({
  //     routeGroup: routeGroup.join("','"),
  //     regionId: RegionSelected === null || RegionSelected === 'Null' || RegionSelected === '' ? '*' : JSON.parse(RegionSelected),
  //     countryId: CountrySelected === null || CountrySelected === 'Null' || CountrySelected === '' ? '*' : JSON.parse(CountrySelected),
  //     routeId: RouteSelected === null || RouteSelected === 'Null' || RouteSelected === '' ? '*' : JSON.parse(RouteSelected),
  //     leg: LegSelected === null || LegSelected === 'Null' || LegSelected === '' ? '*' : `'${LegSelected}'`,
  //     flight: FlightSelected === null || FlightSelected === 'Null' || FlightSelected === '' ? '*' : `'${FlightSelected}'`,
  //     gettingMonth: window.monthNumToName(rangeValue.from.month),
  //     gettingYear: rangeValue.from.year,
  //     getCabinValue: getCabinValue,
  //     cabinSelectedDropDown: cabinSelectedDropDown
  //   }, () => this.getInitialData())
  // }

  // getInitialData() {
  //   let self = this;
  //   let { routeGroup, currency, gettingMonth, gettingYear, regionId, countryId, routeId, leg, flight, getCabinValue } = this.state;

  //   self.setState({ loading: true, loading2: true, firstLoadList: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [] })

  //   self.getInitialListData(regionId, countryId, routeId, leg, flight);

  //   apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue).then(function (result) {
  //     self.setState({ loading: false, firstLoadList: false })
  //     if (result) {
  //       var totalData = result[0].totalData;
  //       var columnName = result[0].columnName;
  //       var routeMonthDetails = result[0].routemonthtableDatas;
  //       var baseAccess = result[0].currentAccess.base_access;

  //       monthData = routeMonthDetails;
  //       self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, baseAccess: baseAccess, monthTotalData: totalData })
  //     }
  //     self.getInitialDrillDownData()
  //   });
  // }

  // getInitialListData = (regionId, countryId, routeId, LEG, FLIGHT) => {
  //   const self = this;
  //   const routeAccess = this.userDetails.route_access
  //   let leg = LEG.substring(1, LEG.length - 1)
  //   let flight = FLIGHT.substring(1, FLIGHT.length - 1)

  //   if (Object.keys(routeAccess).length > 0) {
  //     self.setState({ accessLevelDisable: true })
  //   }
  //   const countryLevelAccess = (routeAccess).hasOwnProperty('selectedRouteCountry')
  //   const routeLevelAccess = (routeAccess).hasOwnProperty('selectedRoute')

  //   if (regionId !== '*') {
  //     bcData.push({ "val": regionId, "title": 'Region', 'disable': countryLevelAccess });
  //     self.setState({ selectedData: regionId })
  //   }
  //   if (countryId !== '*') {
  //     bcData.push({ "val": countryId, "title": 'Country', 'disable': routeLevelAccess });
  //     self.setState({ selectedData: countryId })
  //   }
  //   if (routeId !== '*') {
  //     bcData.push({ "val": routeId, "title": 'Route' });
  //     self.setState({ selectedData: routeId })
  //   }
  //   if (leg !== '*') {
  //     bcData.push({ "val": leg, flight, "title": 'Leg' });
  //     self.setState({ selectedData: LEG })
  //   }
  //   if (flight !== '*') {
  //     bcData.push({ "val": flight, "title": 'Flight' });
  //     self.setState({ selectedData: FLIGHT })
  //   }
  // }

  // getInitialDrillDownData() {
  //   let self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear } = this.state;

  //   if (ancillary) {
  //     this.ancillaryTabClick();
  //   } else if (route) {
  //     this.routeTabClick();
  //   } else {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false });
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   }
  // }

  // getMonthDrillDownData = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear } = this.state;
  //   self.setState({ loading: true, loading2: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [] })
  //   apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue).then(function (result) {
  //     self.setState({ loading: false })
  //     if (result) {
  //       var totalData = result[0].totalData;
  //       var columnName = result[0].columnName;
  //       var routeMonthDetails = result[0].routemonthtableDatas;
  //       self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //     }
  //   });

  //   if (type === 'Null') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   } else if (type === 'RBD') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   } else if (type === 'OD') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   } else if (type === 'Flights') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   } else if (type === 'Cabin') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   } else if (type === 'Ancillary') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //         })
  //       }
  //     });
  //   }
  // }

  // getHighlightedMonth(posMonthdata, month, year) {
  //   let monthNumber = window.monthNameToNum(month)
  //   let data = posMonthdata.filter((data, index) => {
  //     var monthName = data.Month;
  //     const selectedMonth = `${window.shortMonthNumToName(monthNumber)} ${year}`
  //     if (selectedMonth === monthName) {
  //       data.highlightMe = true;
  //     }
  //     if (year === currentYear) {
  //       if (data.Month === `Total ${currentYear - 1}`) {
  //         data.Month = `► Total ${currentYear - 1}`;
  //       }
  //       if (data.Year >= currentYear || data.Month === `► Total ${currentYear - 1}`) {
  //         return data;
  //       }
  //     } else {
  //       if (data.Month === `Total ${currentYear - 1}`) {
  //         data.Month = `▼ Total ${currentYear - 1}`;
  //       }
  //       this.setState({ showLastYearRows: true })
  //       return data;
  //     }
  //   })
  //   return data;
  // }

  // getHighlightedRow(routeMonthdata, month) {
  //   let data = routeMonthdata.map((data, index) => {
  //     var monthName = data.Month;
  //     if (monthName === `▼ Total ${currentYear - 1}` || monthName === `► Total ${currentYear - 1}`) {
  //       data.highlightMe = true;
  //     }
  //     return data;
  //   })
  //   return data;
  // }

  // monthWiseCellClick = (params) => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear } = this.state;
  //   var column = params.colDef.field;

  //   const routeMonthDetails = this.state.routeMonthDetails.map((d) => {
  //     d.highlightMe = false;
  //     return d;
  //   })
  //   params.api.updateRowData({ update: routeMonthDetails });

  //   if (params.data.Month !== `► Total ${currentYear - 1}` && params.data.Month !== `▼ Total ${currentYear - 1}`) {
  //     self.setState({ gettingMonth: params.data.MonthName, gettingYear: params.data.Year })
  //     const range = { from: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthName) }, to: { year: params.data.Year, month: window.monthNameToNum(params.data.MonthName) } }
  //     window.localStorage.setItem('rangeValue', JSON.stringify(range))
  //   } else {
  //     this.setState({ showLastYearRows: !this.state.showLastYearRows },
  //       () => {
  //         const updatedMonthData = monthData.filter((d) => {
  //           if (this.state.showLastYearRows) {
  //             if (d.Month === `► Total ${currentYear - 1}`) {
  //               d.Month = `▼ Total ${currentYear - 1}`
  //             }
  //             return d;
  //           }
  //           else if (d.Year >= currentYear || d.Month === `▼ Total ${currentYear - 1}`) {
  //             if (d.Month === `▼ Total ${currentYear - 1}`) {
  //               d.Month = `► Total ${currentYear - 1}`
  //             }
  //             return d;
  //           }
  //         })
  //         this.setState({ routeMonthDetails: this.getHighlightedRow(updatedMonthData, params.data.Month) })
  //       })
  //   }

  //   if (column === '') {
  //     params.event.stopPropagation();
  //     // apiServices.getPOSRegionTables(gettingMonth, getCabinValue).then(function (result) {
  //     //   var columnName = result[0].columnName;
  //     //   var posRegionata = result[0].posregiontableDatas;
  //     //   self.setState({ chartVisible: true, modelRegionDatas: posRegionata, modelregioncolumn: columnName })
  //     // });
  //     // self.setState({ chartVisible: true })
  //   } else if (column === 'CY_B' && params.data.Month !== `► Total ${currentYear - 1}` && params.data.Month !== `▼ Total ${currentYear - 1}`) {
  //     params.event.stopPropagation();
  //     self.showLoader();
  //     apiServices.getRouteCabinDetails(params.data.Year, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue).then(function (result) {
  //       self.hideLoader();
  //       if (result) {
  //         var columnName = result[0].columnName;
  //         var cabinData = result[0].cabinData;
  //         self.setState({ tableModalVisible: true, modalCompartmentData: cabinData, modalCompartmentColumn: columnName })
  //       }
  //     });

  //   } else if (column === 'Month' && params.data.Month !== `► Total ${currentYear - 1}` && params.data.Month !== `▼ Total ${currentYear - 1}`) {
  //     self.setState({ loading2: true, drillDownData: [], drillDownTotalData: [] })
  //     if (type === 'OD') {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'OD').then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     } else if (type === 'RBD') {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'RBD').then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     } else if (type === 'Flights') {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Flights').then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     } else if (type === 'Cabin') {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     } else if (type === 'Ancillary') {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     } else {
  //       apiServices.getRouteDrillDownData(params.data.Year, currency, params.data.MonthName, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //         self.setState({ loading2: false })
  //         if (result) {
  //           self.setState({
  //             drillDownTotalData: result[0].totalData,
  //             drillDownData: result[0].routeRegionTableDatas,
  //             drillDownColumn: result[0].columnName,
  //             tabName: result[0].tabName,
  //             regionId: result[0].currentAccess.regionId,
  //             countryId: result[0].currentAccess.countryId,
  //             routeId: result[0].currentAccess.routeId,
  //             leg: result[0].currentAccess.leg,
  //             flight: result[0].currentAccess.flight
  //           })
  //         }
  //       });
  //     }
  //   }
  // }

  // routeRegionCellClick = (params) => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, gettingYear } = this.state;
  //   var column = params.colDef.field;
  //   var selectedData = `'${params.data.firstColumnName}'`
  //   var selectedDataWQ = params.data.firstColumnName
  //   var selectedTitle = params.colDef.headerName

  //   self.setState({ selectedData })
  //   let found;
  //   bcData.map((data, i) => data.title === selectedTitle ? found = true : found = false)

  //   const highlightedMonthData = (data) => self.getHighlightedMonth(data, gettingMonth, gettingYear);

  //   this.storeValuesToLS(regionId, countryId, routeId, leg, flight, getCabinValue, selectedDataWQ);

  //   if (column === '') {
  //     // apiServices.getPOSRegionTables(gettingMonth, getCabinValue).then(function (result) {
  //     //   var columnName = result[0].columnName;
  //     //   var posRegionata = result[0].posregiontableDatas;
  //     //   regionthis.setState({ chartVisible: true, modelRegionDatas: posRegionata, modelregioncolumn: columnName })
  //     // });
  //     // self.setState({ chartVisible: true })
  //   } else if (column === 'firstColumnName') {
  //     if (!found) {
  //       let __ = selectedTitle !== 'Cabin' ? bcData.push({ "val": params.data.firstColumnName, "title": selectedTitle }) : null
  //       if (regionId === '*') {
  //         self.setState({ loading: true, loading2: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [] })
  //         apiServices.getRouteMonthTables(currency, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue).then(function (result) {
  //           self.setState({ loading: false })
  //           if (result) {
  //             var totalData = result[0].totalData;
  //             var columnName = result[0].columnName;
  //             var routeMonthDetails = result[0].routemonthtableDatas;
  //             self.setState({ routeMonthDetails: highlightedMonthData(routeMonthDetails), routeMonthColumns: columnName, monthTotalData: totalData })
  //           }
  //         });

  //         apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'Null').then((result) => {
  //           self.setState({ loading2: false })
  //           if (result) {
  //             self.setState({
  //               drillDownTotalData: result[0].totalData,
  //               drillDownData: result[0].routeRegionTableDatas,
  //               drillDownColumn: result[0].columnName,
  //               tabName: result[0].tabName,
  //               regionId: result[0].currentAccess.regionId,
  //               countryId: result[0].currentAccess.countryId,
  //               routeId: result[0].currentAccess.routeId,
  //               leg: result[0].currentAccess.leg,
  //               flight: result[0].currentAccess.flight,
  //             })
  //           }
  //         });
  //       } else if (countryId === '*') {
  //         self.setState({ loading: true, loading2: true, routeMonthDetails: [] })
  //         apiServices.getRouteMonthTables(currency, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue).then(function (result) {
  //           self.setState({ loading: false })
  //           if (result) {
  //             var totalData = result[0].totalData;
  //             var columnName = result[0].columnName;
  //             var routeMonthDetails = result[0].routemonthtableDatas;
  //             self.setState({ routeMonthDetails: highlightedMonthData(routeMonthDetails), routeMonthColumns: columnName, monthTotalData: totalData })
  //           }
  //         });

  //         apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'Null').then((result) => {
  //           self.setState({ loading2: false })
  //           if (result) {
  //             self.setState({
  //               drillDownTotalData: result[0].totalData,
  //               drillDownData: result[0].routeRegionTableDatas,
  //               drillDownColumn: result[0].columnName,
  //               tabName: result[0].tabName,
  //               regionId: result[0].currentAccess.regionId,
  //               countryId: result[0].currentAccess.countryId,
  //               routeId: result[0].currentAccess.routeId,
  //               leg: result[0].currentAccess.leg,
  //               flight: result[0].currentAccess.flight,
  //             })
  //           }
  //         });
  //       } else if (routeId === '*') {
  //         self.setState({ loading: true, loading2: true, routeMonthDetails: [] })
  //         apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue).then(function (result) {
  //           self.setState({ loading: false })
  //           if (result) {
  //             var totalData = result[0].totalData;
  //             var columnName = result[0].columnName;
  //             var routeMonthDetails = result[0].routemonthtableDatas;
  //             self.setState({ routeMonthDetails: highlightedMonthData(routeMonthDetails), routeMonthColumns: columnName, monthTotalData: totalData })
  //           }
  //         });

  //         apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'Null').then((result) => {
  //           self.setState({ loading2: false })
  //           if (result) {
  //             self.setState({
  //               drillDownTotalData: result[0].totalData,
  //               drillDownData: result[0].routeRegionTableDatas,
  //               drillDownColumn: result[0].columnName,
  //               tabName: result[0].tabName,
  //               regionId: result[0].currentAccess.regionId,
  //               countryId: result[0].currentAccess.countryId,
  //               routeId: result[0].currentAccess.routeId,
  //               leg: result[0].currentAccess.leg,
  //               flight: result[0].currentAccess.flight,
  //             })
  //           }
  //         });
  //       } else if (leg === '*') {
  //         self.setState({ loading: true, loading2: true, routeMonthDetails: [] })
  //         apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue).then(function (result) {
  //           self.setState({ loading: false })
  //           if (result) {
  //             var totalData = result[0].totalData;
  //             var columnName = result[0].columnName;
  //             var routeMonthDetails = result[0].routemonthtableDatas;
  //             self.setState({ routeMonthDetails: highlightedMonthData(routeMonthDetails), routeMonthColumns: columnName, monthTotalData: totalData })
  //           }
  //         });

  //         apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'Null').then((result) => {
  //           self.setState({ loading2: false })
  //           if (result) {
  //             self.setState({
  //               drillDownTotalData: result[0].totalData,
  //               drillDownData: result[0].routeRegionTableDatas,
  //               drillDownColumn: result[0].columnName,
  //               tabName: result[0].tabName,
  //               regionId: result[0].currentAccess.regionId,
  //               countryId: result[0].currentAccess.countryId,
  //               routeId: result[0].currentAccess.routeId,
  //               leg: result[0].currentAccess.leg,
  //               flight: result[0].currentAccess.flight,
  //             })
  //           }
  //         });
  //       } else if (flight === '*') {
  //         self.setState({ loading: true, loading2: true, routeMonthDetails: [] })
  //         apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue).then(function (result) {
  //           self.setState({ loading: false })
  //           if (result) {
  //             var totalData = result[0].totalData;
  //             var columnName = result[0].columnName;
  //             var routeMonthDetails = result[0].routemonthtableDatas;
  //             self.setState({ routeMonthDetails: highlightedMonthData(routeMonthDetails), routeMonthColumns: columnName, monthTotalData: totalData })
  //           }
  //         });

  //         apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'Null').then((result) => {
  //           self.setState({ loading2: false })
  //           if (result) {
  //             self.setState({
  //               drillDownTotalData: result[0].totalData,
  //               drillDownData: result[0].routeRegionTableDatas,
  //               drillDownColumn: result[0].columnName,
  //               tabName: result[0].tabName,
  //               regionId: result[0].currentAccess.regionId,
  //               countryId: result[0].currentAccess.countryId,
  //               routeId: result[0].currentAccess.routeId,
  //               leg: result[0].currentAccess.leg,
  //               flight: result[0].currentAccess.flight,
  //             })
  //           }
  //         });
  //       }
  //     }
  //   }
  // }

  // rectifyURLValues(regionId, countryId, routeId, leg) {

  //   if (Array.isArray(regionId)) {
  //     this.selectedRegion = regionId.join(',')
  //   } else if (regionId.includes("','")) {
  //     this.regionId = regionId.split("','").join(',')
  //   } else {
  //     this.selectedRegion = regionId
  //     this.selectedRegion = this.selectedRegion.substring(1, this.selectedRegion.length - 1);
  //   }

  //   if (Array.isArray(countryId)) {
  //     this.selectedCountry = countryId.join(',')
  //   } else if (regionId.includes("','")) {
  //     this.selectedCountry = countryId.split("','").join(',')
  //     this.selectedCountry = this.selectedCountry.substring(1, this.selectedCountry.length - 1);
  //   } else {
  //     this.selectedCountry = countryId
  //     this.selectedCountry = this.selectedCountry.substring(1, this.selectedCountry.length - 1);
  //   }

  //   if (Array.isArray(routeId)) {
  //     this.selectedRoute = routeId.join(',')
  //   } else if (regionId.includes("','")) {
  //     this.selectedRoute = routeId.split("','").join(',')
  //     this.selectedRoute = this.selectedRoute.substring(1, this.selectedRoute.length - 1);
  //   } else {
  //     this.selectedRoute = routeId
  //     this.selectedRoute = this.selectedRoute.substring(1, this.selectedRoute.length - 1);
  //   }

  //   this.selectedLeg = leg
  //   this.selectedLeg = this.selectedLeg.substring(1, this.selectedLeg.length - 1);
  // }

  // storeValuesToLS(regionId, countryId, routeId, leg, flight, getCabinValue, data) {
  //   let region = []
  //   let country = []
  //   let route = []
  //   let cabin = []

  //   this.rectifyURLValues(regionId, countryId, routeId, leg);

  //   if (regionId === '*') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(data)}`)
  //     region.push(data)
  //     window.localStorage.setItem('RouteRegionSelected', JSON.stringify(region))

  //   } else if (countryId === '*') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${(data)}`)
  //     country.push(data)
  //     window.localStorage.setItem('RouteCountrySelected', JSON.stringify(country))

  //   } else if (routeId === '*') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${data}`)
  //     route.push(data)
  //     window.localStorage.setItem('RouteSelected', JSON.stringify(route))

  //   } else if (leg === '*') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${this.selectedRoute}&Leg=${data}`)
  //     window.localStorage.setItem('LegSelected', data)

  //   } else if (flight === '*') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${this.selectedRoute}&Leg=${this.selectedLeg}&Flight=${data}`)
  //     window.localStorage.setItem('FlightSelected', data)

  //   } else if (getCabinValue === 'Null') {
  //     cabin.push(data)
  //     window.localStorage.setItem('CabinSelected', JSON.stringify(cabin))
  //   }
  // }

  // regionTabClick = () => {
  //   var self = this;
  //   self.setState({ type: 'Null', loading2: true, drillDownData: [], drillDownTotalData: [] })
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, tabName, gettingYear } = this.state;

  //   if (tabName === 'Region') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (tabName === 'Country') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, '*', '*', '*', '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (tabName === 'Route') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, '*', '*', '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (tabName === 'Leg') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, '*', '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (tabName === 'Flight') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (tabName === 'Cabin') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   }
  // }

  // routeTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'OD', loading2: true, drillDownData: [], drillDownTotalData: [] })

  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // rbdTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'RBD', loading2: true, drillDownData: [], drillDownTotalData: [] })

  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // legTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'leg', loading2: true, drillDownData: [], drillDownTotalData: [] })
  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'leg').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // flightsTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'Flights', loading2: true, drillDownData: [], drillDownTotalData: [] })
  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // compartmentTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'Cabin', loading2: true, drillDownData: [], drillDownTotalData: [] })

  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // ancillaryTabClick = () => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, selectedData, gettingYear } = this.state;
  //   self.setState({ type: 'Ancillary', loading2: true, drillDownData: [], drillDownTotalData: [] })

  //   if (regionId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (countryId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, selectedData, countryId, routeId, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (routeId === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, routeId, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (leg === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else if (flight === '*') {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   } else {

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           tabName: result[0].firstTabName
  //         })
  //       }
  //     });
  //   }
  // }

  // posContributionClick = () => {
  //   var self = this;
  //   let { currency, gettingMonth, routeId, getCabinValue, gettingYear } = this.state;
  //   self.setState({ odData: [], segmentData: [] })
  //   this.showLoader();
  //   apiServices.getPOSContributionData(gettingYear, currency, gettingMonth, routeId, getCabinValue).then((result) => {
  //     this.hideLoader();
  //     if (result) {
  //       self.setState({
  //         // drillDownTotalData: result[0].totalData,
  //         posContributionModal: true,
  //         odData: result[0].rowDataOD,
  //         odColumn: result[0].columnNameOD,
  //         segmentData: result[0].rowDataSeg,
  //         segmentColumn: result[0].columnNameSeg,
  //       })
  //     }
  //   });
  // }

  // cabinSelectChange = (e) => {
  //   e.preventDefault();
  //   const getCabinValue = e.target.value;

  //   this.setState({
  //     getCabinValue: getCabinValue,
  //     cabinSelectedDropDown: getCabinValue,
  //   }, () => {
  //     window.localStorage.setItem('CabinSelected', JSON.stringify(getCabinValue));
  //   })
  // }

  // onCabinClose() {
  //   var self = this;
  //   self.sendEvent('2', 'clicked on Cabin drop down', '/route', 'Route Page');
  //   let { cabinSelectedDropDown } = this.state;

  //   if (cabinSelectedDropDown.length > 0) {
  //     this.getDataOnCabinChange()
  //   } else {
  //     this.setState({ getCabinValue: 'Null' }, () => this.getDataOnCabinChange())
  //     window.localStorage.setItem('CabinSelected', 'Null');
  //   }
  // }

  // getDataOnCabinChange() {
  //   var self = this;
  //   self.setState({
  //     loading: true, loading2: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: []
  //   })
  //   let { routeGroup, currency, getCabinValue, gettingMonth, regionId, countryId, routeId, leg, flight, type, gettingYear } = this.state;

  //   apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue).then(function (result) {
  //     self.setState({ loading: false })
  //     if (result) {
  //       var totalData = result[0].totalData;
  //       var columnName = result[0].columnName;
  //       var routeMonthDetails = result[0].routemonthtableDatas;
  //       self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //     }
  //   });

  //   if (type === 'OD') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (type === 'RBD') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false, drillDownData: [], })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (type === 'Flights') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false, drillDownData: [], })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (type === 'Cabin') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Cabin').then((result) => {
  //       self.setState({ loading2: false, drillDownData: [], })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (type === 'Ancillary') {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, 'Ancillary').then((result) => {
  //       self.setState({ loading2: false, drillDownData: [], })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else {
  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false, drillDownData: [], })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   }
  // }

  // homeHandleClick = (e) => {
  //   var self = this;
  //   let { routeGroup, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear } = this.state;
  //   self.setState({ loading: true, loading2: true, firstHome: false, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [], odData: [], segmentData: [] })

  //   window.localStorage.setItem('RouteRegionSelected', 'Null');
  //   window.localStorage.setItem('RouteCountrySelected', 'Null');
  //   window.localStorage.setItem('RouteSelected', 'Null');
  //   window.localStorage.setItem('LegSelected', 'Null');
  //   window.localStorage.setItem('FlightSelected', 'Null');

  //   apiServices.getRouteMonthTables('bc', routeGroup, '*', '*', '*', '*', '*', getCabinValue).then(function (result) {
  //     self.setState({ loading: false })
  //     if (result) {
  //       var totalData = result[0].totalData;
  //       var columnName = result[0].columnName;
  //       var routeMonthDetails = result[0].routemonthtableDatas;
  //       self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //     }
  //   });

  //   if (type === 'Null') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, 'Null').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].tabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (type === 'RBD') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, 'RBD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (type === 'OD') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, 'OD').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (type === 'Flights') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, 'Flights').then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (type === 'Cabin') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (type === 'Ancillary') {
  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, '*', '*', '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   }
  //   bcData = [];
  //   this.props.history.push(`/route?RouteGroup=${routeGroup}`)
  // }

  // listHandleClick = (data, title) => {
  //   var self = this;
  //   let { gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear, currency } = this.state;
  //   var selectedData = data;
  //   if (selectedData.charAt(0) !== "'" && selectedData.charAt(selectedData.length - 1) !== "'") {
  //     selectedData = `'${data}'`
  //   }
  //   if ((data).includes(',')) {
  //     selectedData = `'${data.split(',').join("','")}'`;
  //   }
  //   self.setState({ selectedData, loading: true, loading2: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [] })
  //   var getColName = title;

  //   var indexEnd = bcData.findIndex(function (d) {
  //     return d.title == title;
  //   })
  //   var removeArrayIndex = bcData.slice(0, indexEnd + 1);
  //   bcData = removeArrayIndex;

  //   this.changeURLOnListClick(regionId, countryId, routeId, leg, data, getColName)

  //   if (getColName === 'Region') {
  //     apiServices.getRouteMonthTables('bc', routeGroup, selectedData, '*', '*', '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, selectedData, '*', '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Country') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, selectedData, '*', '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Route') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, selectedData, '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Leg') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, selectedData, '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Flight') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   }

  // }

  // browserBackButtonClick = (data, title) => {
  //   var self = this;
  //   let { routeGroup, currency, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, type, gettingYear } = this.state;
  //   var selectedData = data;
  //   if (selectedData.charAt(0) !== "'" && selectedData.charAt(selectedData.length - 1) !== "'") {
  //     selectedData = `'${data}'`
  //   }
  //   if ((data).includes(',')) {
  //     selectedData = `'${data.split(',').join("','")}'`;
  //   }
  //   self.setState({ selectedData, loading: true, loading2: true, routeMonthDetails: [], monthTotalData: [], drillDownData: [], drillDownTotalData: [], odData: [], segmentData: [] })
  //   var getColName = title;

  //   this.onBackPressClearLS(getColName)

  //   if (getColName === 'Region') {
  //     apiServices.getRouteMonthTables('bc', routeGroup, selectedData, '*', '*', '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, 'bc', gettingMonth, routeGroup, selectedData, '*', '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight,
  //           currency: 'bc'
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Country') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, selectedData, '*', '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, selectedData, '*', '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Route') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, selectedData, '*', '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, selectedData, '*', '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Leg') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, selectedData, '*', getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, selectedData, '*', getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   } else if (getColName === 'Flight') {

  //     apiServices.getRouteMonthTables(currency, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue).then(function (result) {
  //       self.setState({ loading: false })
  //       if (result) {
  //         var totalData = result[0].totalData;
  //         var columnName = result[0].columnName;
  //         var routeMonthDetails = result[0].routemonthtableDatas;
  //         self.setState({ routeMonthDetails: self.getHighlightedMonth(routeMonthDetails, gettingMonth, gettingYear), routeMonthColumns: columnName, monthTotalData: totalData })
  //       }
  //     });

  //     apiServices.getRouteDrillDownData(gettingYear, currency, gettingMonth, routeGroup, regionId, countryId, routeId, leg, selectedData, getCabinValue, type).then((result) => {
  //       self.setState({ loading2: false })
  //       if (result) {
  //         self.setState({
  //           drillDownTotalData: result[0].totalData,
  //           drillDownData: result[0].routeRegionTableDatas,
  //           drillDownColumn: result[0].columnName,
  //           tabName: result[0].firstTabName,
  //           regionId: result[0].currentAccess.regionId,
  //           countryId: result[0].currentAccess.countryId,
  //           routeId: result[0].currentAccess.routeId,
  //           leg: result[0].currentAccess.leg,
  //           flight: result[0].currentAccess.flight
  //         })
  //       }
  //     });
  //   }

  // }

  // changeURLOnListClick(regionId, countryId, routeId, leg, selectedData, getColName) {

  //   this.rectifyURLValues(regionId, countryId, routeId, leg);

  //   if (getColName === 'Region') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(selectedData)}`)
  //     window.localStorage.setItem('RouteCountrySelected', 'Null');
  //     window.localStorage.setItem('RouteSelected', 'Null');
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Country') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${(selectedData)}`)
  //     window.localStorage.setItem('RouteSelected', 'Null');
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Route') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${selectedData}`)
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Leg') {
  //     window.localStorage.setItem('FlightSelected', 'Null');
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${this.selectedRoute}&Leg=${selectedData}`)

  //   } else if (getColName === 'Flight') {
  //     this.props.history.push(`/route?RouteGroup=${this.state.routeGroup}&Region=${encodeURIComponent(this.selectedRegion)}&Country=${this.selectedCountry}&Route=${this.selectedRoute}&Leg=${this.selectedLeg}&Flight=${selectedData}`)
  //   }
  // }

  // onBackPressClearLS(getColName) {

  //   if (getColName === 'Region') {
  //     window.localStorage.setItem('RouteCountrySelected', 'Null');
  //     window.localStorage.setItem('RouteSelected', 'Null');
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Country') {
  //     window.localStorage.setItem('RouteSelected', 'Null');
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Route') {
  //     window.localStorage.setItem('LegSelected', 'Null');
  //     window.localStorage.setItem('FlightSelected', 'Null');

  //   } else if (getColName === 'Leg') {
  //     window.localStorage.setItem('FlightSelected', 'Null');
  //   }
  // }

  currency = (e) => {
    let currency = e.target.value;
    this.setState({ currency: currency }, () => this.getMonthDrillDownData())
  }

  closeChartModal() {
    this.setState({ chartVisible: false })
  }

  closeTableModal() {
    this.setState({ tableModalVisible: false })
  }

  redirection = (e) => {
    this.sendEvent('2', 'clicked on POS/Route drop down', '/route', 'Route Page');
    let name = e.target.value;
    this.url = '/pos';
    let regionId = window.localStorage.getItem('RegionSelected')
    let countryId = window.localStorage.getItem('CountrySelected')
    let cityId = window.localStorage.getItem('CitySelected')
    let commonOD = window.localStorage.getItem('ODSelected')
    if (regionId !== null && regionId !== 'Null') {
      regionId = JSON.parse(regionId)
      this.url = `/pos?Region=${String.removeQuotes(regionId)}`
    }
    if (countryId !== null && countryId !== 'Null') {
      countryId = JSON.parse(countryId)
      this.url = `/pos?Region=${String.removeQuotes(regionId)}&Country=${String.removeQuotes(countryId)}`
    }
    if (cityId !== null && cityId !== 'Null') {
      cityId = JSON.parse(cityId)
      this.url = `/pos?Region=${String.removeQuotes(regionId)}&Country=${String.removeQuotes(countryId)}&POS=${String.removeQuotes(cityId)}`
    }
    if (commonOD !== null && commonOD !== 'Null' && commonOD !== '') {
      this.url = `/pos?Region=${String.removeQuotes(regionId)}&Country=${String.removeQuotes(countryId)}&POS=${String.removeQuotes(cityId)}&${encodeURIComponent('O&D')}=${String.removeQuotes(commonOD)}`
    }
    if (name === 'POS') {
      this.props.history.push(this.url)
      bcData = []
    } else {
      this.props.history.push('/route')
      bcData = []
    }
  }

  callAccess(routeGroup) {
    let routeGroupArray = [];
    routeGroupArray.push(routeGroup)
    window.localStorage.setItem('RouteGroupSelected', JSON.stringify(routeGroupArray));
    this.setState({ routeGroup }, () => this.homeHandleClick())
  }

  showLoader = () => {
    $("#loaderImage").addClass("loader-visible")
  }

  hideLoader = () => {
    $("#loaderImage").removeClass("loader-visible")
    $(".x_panel").addClass("opacity-fade");
    $(".top-buttons").addClass("opacity-fade");
  }

  renderTabs() {
    let { tabName, gettingMonth, regionId, countryId, routeId, leg, flight, getCabinValue, routeGroup, gettingYear, type } = this.state;
    const downloadURLDrillDown = apiServices.exportCSVRouteDrillDownURL(gettingYear, gettingMonth, routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue, type)
    const downloadURLMonthly = apiServices.exportCSVRouteMonthlyURL(routeGroup, regionId, countryId, routeId, leg, flight, getCabinValue)

    return (
      <ul className="nav nav-tabs" role="tablist">

        <li role="presentation" className="active" onClick={this.routeTabClick}>
          <a href="#Section1" aria-controls="profile" role="tab" data-toggle="tab">
            Region
             </a>
        </li>


        <li role="presentation" onClick={this.selectedLegTabClick}>
          <a href="#Section2" aria-controls="messages" role="tab" data-toggle="tab">
            Hub
            </a>
        </li>


        <li role="presentation" onClick={this.flightsTabClick}>
          <a href="#Section3" aria-controls="messages" role="tab" data-toggle="tab">
            Rbd based
            </a>
        </li>

        <li id='regionTab' role="presentation" onClick={this.regionTabClick}>
          <a href="#Section4" aria-controls="profile" role="tab" data-toggle="tab">
            Leg based
          </a>
        </li>

        <li id='regionTab' role="presentation" onClick={this.regionTabClick}>
          <a href="#Section5" aria-controls="profile" role="tab" data-toggle="tab">
            Flight based
          </a>
        </li>

        {/* <DownloadCSV url={downloadURLDrillDown} name={`Route DRILLDOWN`} path={`/route`} page={`Route Page`} />
        <DownloadCSV url={downloadURLMonthly} name={`Route MONTHLY`} path={`/route`} page={`Route Page`} />
        {routeId !== '*' ? <button className='btn download' onClick={this.posContributionClick}>POS Contribution</button> : ''} */}

      </ul>
    )
  }

  renderPosContributionModal() {
    return (
      <Modal
        show={this.state.posContributionModal}
        onHide={() => this.setState({ posContributionModal: false })}
        aria-labelledby="ModalHeader"
        className='posContri'
      >
        <Modal.Header closeButton>
          <Modal.Title id='ModalHeader' title={`POS Contribution for ${String.removeQuotes(this.state.routeId)}`}>{`POS Contribution for ${String.removeQuotes(this.state.routeId)}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '49%' }}>
            <h3 className='headingPOS'>OD Wise</h3>
            <DataTableComponent
              rowData={this.state.odData}
              columnDefs={this.state.odColumn}
              route={true}
            />
          </div>
          <div style={{ width: '49%' }}>
            <h3 className='headingPOS'>Segment Wise</h3>
            <DataTableComponent
              rowData={this.state.segmentData}
              columnDefs={this.state.segmentColumn}
              route={true}
            />
          </div>
        </Modal.Body>
      </Modal >
    )
  }

  render() {
    const { cabinOption, cabinSelectedDropDown, cabinDisable, routeId, type } = this.state;
    return (
      <div className='routePerfCons'>
        <TopMenuBar {...this.props} />
        <Loader />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 top">
            <div className="navdesign" style={{ marginTop: '0px' }}>
              <div className="col-md-7 col-sm-7 col-xs-12 toggle1">
                <select className="form-control cabinselect pos-route-dropdown" onChange={(e) => this.redirection(e)} >
                  <option value='POS'>POS</option>
                  <option value='Route' selected={true}>Route</option>
                </select>
                {this.state.firstLoadList ? "" :
                  <div className='route-access'>
                    {this.state.routeGroup}
                    <div className="triangle-up"></div>
                    <div className='route-groups'>
                      <div className={`route-main ${this.state.accessLevelDisable ? ' route-main-disable' : ''}`}>
                        <span className={`${this.state.accessLevelDisable ? ' route-access-disable' : ''}`} onClick={() => this.callAccess('Network')}>
                          Network
                          </span>
                      </div>
                      <div className={`route-main ${this.state.accessLevelDisable ? this.state.routeGroup === 'Domestic' ? '' : 'route-main-disable' : ''}`}>
                        <span className={`${this.state.accessLevelDisable ? this.state.routeGroup === 'Domestic' ? '' : 'route-access-disable' : ''}`} onClick={() => this.callAccess('Domestic')}>
                          Domestic
                          </span>
                      </div>
                      <div className={`route-main international ${this.state.accessLevelDisable ? this.state.routeGroup === 'International' ? '' : 'route-main-disable' : ''}`}>
                        <span className={`${this.state.accessLevelDisable ? this.state.routeGroup === 'International' ? '' : 'route-access-disable' : ''}`} onClick={() => this.callAccess('International')}>
                          International
                          </span>
                      </div>
                    </div>
                  </div>
                  //  <select className="form-control cabinselect currency-dropdown route-access" onChange={(e) => this.callAccess(e)} disabled={this.state.accessLevelDisable ? true : false}>
                  //   <option value='Network'>Network</option>
                  //   <option value='Domestic' selected={this.state.routeGroup === 'Domestic'}>Domestic</option>
                  //   <option value='International' selected={this.state.routeGroup === 'International'}>International</option>
                  // </select>
                }
                <section>
                  <nav>
                    <ol className="cd-breadcrumb">
                      {/* {this.state.baseAccess !== '' ? <li onClick={this.homeHandleClick} > {this.state.baseAccess} </li> : ''} */}
                      {this.state.firstLoadList ? "" : bcData.map((item) =>
                        <div style={{ cursor: item.disable ? 'not-allowed' : 'pointer' }}>
                          <li className={`${item.disable ? 'breadcrumb-disable' : ''}`} onClick={(e) => this.listHandleClick(e.target.id, item.title)} id={item.val} title={`${item.title} : ${item.val}`}>
                            {` > ${item.val}`}
                          </li>
                        </div>
                      )}
                    </ol>
                  </nav>
                </section>
              </div>

              <div className="col-md-5 col-sm-5 col-xs-12 toggle2">
                <div className='cabin-selection'>
                  <h4>Select Cabin :</h4>
                  <FormControl className="select-group">
                    <InputLabel id="demo-mutiple-checkbox-label">All</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      className={`${cabinDisable ? 'disable' : ''}`}
                      id={`demo-mutiple-checkbox`}
                      multiple
                      value={cabinSelectedDropDown}
                      onChange={(e) => this.cabinSelectChange(e)}
                      input={<Input />}
                      renderValue={selected => {
                        return selected.join(',')
                      }}
                      onClose={() => this.onCabinClose()}
                      MenuProps={{ classes: 'disable' }}
                    >
                      {cabinOption.map(item => (
                        <MenuItem key={item.ClassValue} value={item.ClassValue}>
                          <Checkbox checked={cabinSelectedDropDown.indexOf(item.ClassText) > -1} />
                          <ListItemText primary={item.ClassText} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <h4>Select Currency :</h4>
                <select className="form-control cabinselect currency-dropdown" onChange={(e) => this.currency(e)} disabled={this.state.countryId === '*' ? true : false}>
                  <option value='bc' selected={this.state.countryId === '*' || this.state.toggle === 'bc' ? true : false}>BC</option>
                  <option value='lc'>LC</option>
                </select>

              </div>

            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">

            <div className="x_panel" style={{ marginTop: "10px", height: 'calc(100vh - 130px)' }}>
              <div className="x_content">

                <DataTableComponent
                  rowData={this.state.routeMonthDetails}
                  columnDefs={this.state.routeMonthColumns}
                  onCellClicked={(cellData) => this.monthWiseCellClick(cellData)}
                  loading={this.state.loading}
                  rowClassRules={this.state.routeMonthRowClassRule}
                  frameworkComponents={{ customHeaderGroupComponent: RouteCustomHeaderGroup }}
                  route={true}
                />
                <TotalRow
                  rowData={this.state.monthTotalData}
                  columnDefs={this.state.routeMonthColumns}
                  loading={this.state.loading}
                  frameworkComponents={{ customHeaderGroupComponent: RouteCustomHeaderGroup }}
                  responsive={true}
                  reducingPadding={true}
                />

                <div className="tab" id="posTableTab" role="tabpanel" style={{ marginTop: '10px' }}>

                  {this.renderTabs()}

                  <div className="tab-content tabs">

                    <div role="tabpanel" className={`tab-pane fade in ${ancillary ? '' : route ? '' : "active"}`} id="Section1">

                      {/* Region */}
                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        onCellClicked={(cellData) => this.routeRegionCellClick(cellData)}
                        loading={this.state.loading2}
                        route={true}
                      />
                      <TotalRow
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        responsive={true}
                        reducingPadding={true}
                      />

                    </div>

                    {/* HUB */}
                    <div role="tabpanel" className="tab-pane fade" id="Section2">


                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        route={true}
                      />
                      <TotalRow
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        responsive={true}
                        reducingPadding={true}
                      />

                    </div>

                    {/* RBD */}
                    <div role="tabpanel" className={`tab-pane fade in ${route ? 'active' : ""}`} id="Section3">

                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        route={true}
                      />
                      <TotalRow
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        responsive={true}
                        reducingPadding={true}
                      />

                    </div>

                    {/* Leg */}
                    <div role="tabpanel" className="tab-pane fade" id="Section4">

                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        route={true}
                      />
                      <TotalRow
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        responsive={true}
                        reducingPadding={true}
                      />

                    </div>

                    {/* Flights */}
                    <div role="tabpanel" className="tab-pane fade" id="Section5">

                      <DataTableComponent
                        rowData={this.state.drillDownData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        route={true}
                      />
                      <TotalRow
                        rowData={this.state.drillDownTotalData}
                        columnDefs={this.state.drillDownColumn}
                        loading={this.state.loading2}
                        responsive={true}
                        reducingPadding={true}
                      />

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div>
          <DatatableModelDetails
            tableModalVisible={this.state.tableModalVisible}
            rowData={this.state.modalCompartmentData}
            columns={this.state.modalCompartmentColumn}
            header={`${this.state.gettingMonth} ${this.state.gettingYear}`}
          />
          <ChartModelDetails
            chartVisible={this.state.chartVisible}
            datas={this.state.modelRegionDatas}
            columns={this.state.modelregioncolumn}
            closeChartModal={() => this.closeChartModal()}
          />
          {this.renderPosContributionModal()}
        </div>

      </div>

    );
  }
}

const NewComponentRoute = BrowserToProps(RoutePerfCons);

export default NewComponentRoute;
